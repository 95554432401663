/* ---------------------------------------------------
  Payment Css
----------------------------------------------------- */

/* payment.css */

/* Change the color of the label to white */

/* Additional styling for the payment form */
.payment-parent {
  background-color: #f0f0f0; 
  padding: 20px;
  border-radius: 10px;
}




.payment-body {
    padding: 20px;
    font-family: "Raleway";
    font-size: 1.2em;
    color: var(--dark-terminal-color);
    display: block;
  }
  main {
    width: 480px;
  }
  
  form > * {
    margin: 10px 0;
  }

  /* button {
    background-color: var(--accent-color);
  }
  
  button {
    background: var(--accent-color);
    border-radius: var(--radius);
    color: white;
    border: 0;
    padding: 12px 16px;
    margin-top: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
  }
  button:hover {
    filter: contrast(115%);
  }
  button:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
  }
  button:disabled {
    opacity: 0.5;
    cursor: none;
  } */
  
  input,
  select {
    display: block;
    font-size: 1.1em;
    width: 100%;
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    color: yellow;
    margin-bottom: 500px;
  }
  
  a {
    color: var(--accent-color);
    font-weight: 900;
  }
  
  small {
    font-size: 0.6em;
  }
  
  fieldset,
  input,
  select {
    border: 1px solid #efefef;
  }
  
  #payment-form {
    border: #f6f9fc solid 1px;
    border-radius: var(--radius);
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%),
      0 30px 60px -30px rgb(0 0 0 / 30%);
  }
  
  #messages {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
    display: none; 
    background-color: #0a253c;
    color: #00d924;
    padding: 20px;
    margin: 20px 0;
    border-radius: var(--radius);
    font-size: 0.7em;
  }
  .p-PaymentDetails-group label.p-FieldLabel.Label.Label--empty{
    color : #fff !important
  }
 .payment-parent .Label{
    color: red !important; 
  }

