@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&family=Krona+One&display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "react-alice-carousel/lib/alice-carousel.css";

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

/* ///////////////// */
body.light-mode .banner h3 {
  color: #000;
  /* filter: grayscale(1); */
}

body.light-mode .banner h4 {
  color: #000;
}

body.light-mode .marqueetext {
  background: rgb(41 35 141 / 78%);
}

body.light-mode .entertainmenttopbg-inner {
  background: rgb(255 255 255);
  border: 3px solid #b33afe;
}

body.light-mode .banner p {
  color: #000;
}

body.light-mode {
  background: #fff;
}

body.light-mode .navigation {
  background: #662e9308;
}

body.light-mode .navigation .ant-menu-horizontal .ant-menu-item {
  color: #000;
}

body.light-mode .profileName {
  color: #000;
}

body.light-mode .searchbar-top {
  background-color: #fff;
  box-shadow: 0px 0px 11px 0px #662e9342;
}

body.light-mode .banner ul li {
  color: #000;
}

body.light-mode .mainbtn {
  color: #000;
  background: var(
    --Linear,
    linear-gradient(180deg, #ffffff00 0%, #ffffff00 100%)
  ) !important;
  border: 2px solid #b33afe;
  /* background: var(--Linear, linear-gradient(180deg, #7f00ff 0%, #a020f040 100%)) !important; */
}

body.light-mode .mainbtn:hover {
  background: var(
    --Linear,
    linear-gradient(180deg, #7f00ff 0%, #a020f0 100%)
  ) !important;
}

body.light-mode .container .card .card__head {
  /* background: #9491c6; */
  /* color: #000; */
}

body.light-mode .entertainmentbottom h5,
.inner-page h5 {
  color: #000;
}

body.light-mode .entertainmentbottom p {
  color: #000;
}

body.light-mode .gettickets h3 {
  color: #fff;
}

body.light-mode .gettickets p {
  color: #fff;
}
body.light-mode .gettickets .mainbtn {
  color: #fff;
  border-color: #fff !important;
}

/* body.light-mode .footer ul li a {
  color: #000;
} */

body.light-mode .bottom p {
  color: #000;
}

body.light-mode .entertainmentbottom {
  border-color: #b33afe;
}

body.light-mode .gettickets .mainbtn {
  border-color: rgb(0 0 0 / 74%);
}

body.light-mode .contentarea {
  background-image: none;
}

body.light-mode .entertainmentbottom h5,
body.light-mode .inner-page h5 {
  color: #000;
}

body.light-mode .details-card {
  background: #662e9317;
}
body.light-mode .left-div {
  background: rgb(102 46 147);
}
body.light-mode .tabNavigation.active {
  background: rgb(180 98 247 / 40%);
}
body.light-mode .logo-rectangle {
  background: #662e9317;
}
body.light-mode .navigation svg {
  color: #000;
}
body.light-mode .ant-drawer .ant-drawer-content {
  background: #662e93;
}
body.light-mode .ant-drawer .ant-menu-light {
  background: #662e93;
}
body.light-mode .ant-drawer .ant-menu-light .ant-menu-item {
  color: #fff;
}
body.light-mode .ant-drawer .ant-drawer-close {
  color: #fff;
}
body.light-mode .categeorySlider {
  border: 1px solid #662e93;
  background: #662e9317;
}
body.light-mode .topCategeorySlider span {
  color: #8f10f7;
}
body.light-mode .apply-btn .mainbtn {
  color: #fff;
}
body.light-mode .clear-btn .mainbtn {
  color: #fff;
}

body.light-mode .bottom {
  border-top: 2px solid #8f10f7;
  background: #8f10f7;
  color: #fff;
}

body.light-mode .bottom p {
  color: #fff;
}
body.light-mode .footer {
  background-color: rgb(102 46 147);
}

body.light-mode .footer ul li a {
  color: #fff;
}

body.light-mode .left-div .ant-picker .ant-picker-suffix {
  color: #fff;
}

body.light-mode .left-div ::placeholder {
  color: #fff !important;
}
body.light-mode .banner {
  /* background-image: url(../public/images/night-banner.jpg); */
  background-image: none;
}
body.light-mode .homesection .text-white {
  color: black !important;
}

body.light-mode .blogCard p {
  color: #000 !important;
}
body.light-mode .blogCard h3 {
  color: #000 !important;
}
body.light-mode .blogCard h4 {
  color: #000;
}
body.light-mode .onlyeventblogcard {
  border-color: #9410ffad;
  box-shadow: 0px 0px 4px 0px #000 !important;
}

body.light-mode .trending h2, 
body.light-mode  .testimonials h2, 
body.light-mode  .blog h2, 
body.light-mode .footer h2, 
body.light-mode  .f-50{
  color: #000;
}
/* ///////////////// */

.switch {
  display: inline-block;
  position: relative;
}

.switch__input {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.switch__label {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 26px;
  background-color: #2b2b2b;
  border: 3px solid #5b5b5b;
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  margin: 0;
}

.switch__indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-72%);
  display: block;
  width: 20px;
  height: 20px;
  background-color: #7b7b7b;
  border-radius: 9999px;
  box-shadow: 10px 0px 0 0 rgba(0, 0, 0, 0.2) inset;
}

.switch__indicator::before,
.switch__indicator::after {
  position: absolute;
  content: "";
  display: block;
  background-color: #ffffff;
  border-radius: 9999px;
}

.switch__indicator::before {
  top: 10px;
  left: 9px;
  width: 7px;
  height: 7px;
  background-color: #ffffff;
  opacity: 0.6;
}

.switch__indicator::after {
  bottom: 10px;
  right: 10px;
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  opacity: 0.8;
}

.switch__decoration {
  position: absolute;
  top: 65%;
  left: 50%;
  display: block;
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  border-radius: 9999px;
  animation: twinkle 0.8s infinite -0.6s;
}

.switch__decoration::before,
.switch__decoration::after {
  position: absolute;
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  border-radius: 9999px;
}

.switch__decoration::before {
  top: -20px;
  left: 10px;
  opacity: 1;
  animation: twinkle 0.6s infinite;
}

.switch__decoration::after {
  top: -7px;
  left: 30px;
  animation: twinkle 0.6s infinite -0.2s;
}

@keyframes twinkle {
  50% {
    opacity: 0.2;
  }
}

.switch__indicator,
.switch__indicator::before,
.switch__indicator::after {
  transition: all 0.4s cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

.switch__input:checked + .switch__label {
  background-color: #8fb5f5;
  border-color: #347cf8;
}

.switch__input:checked + .switch__label .switch__indicator {
  background-color: #ecd21f;
  box-shadow: none;
  transform: translate(-50%, -50%) translateX(72%);
}

.switch__input:checked + .switch__label .switch__indicator::before,
.switch__input:checked + .switch__label .switch__indicator::after {
  display: none;
}

.switch__input:checked + .switch__label .switch__decoration {
  top: 50%;
  transform: translate(0%, -50%);
  animation: cloud 8s linear infinite;
  width: 10px;
  height: 13px;
}

.switch__input:checked + .switch__label .switch__decoration::before {
  width: 9px;
  height: 8px;
  top: auto;
  bottom: 0;
  left: -8px;
  animation: none;
}

.switch__input:checked + .switch__label .switch__decoration::after {
  width: 10px;
  height: 11px;
  top: auto;
  bottom: 0;
  left: 9px;
  animation: none;
}

.switch__input:checked + .switch__label .switch__decoration,
.switch__input:checked + .switch__label .switch__decoration::before,
.switch__input:checked + .switch__label .switch__decoration::after {
  border-radius: 9999px 9999px 0 0;
}

.switch__input:checked + .switch__label .switch__decoration::after {
  border-bottom-right-radius: 9999px;
}

@keyframes cloud {
  0% {
    transform: translate(0%, -50%);
  }

  50% {
    transform: translate(-50%, -50%);
  }

  100% {
    transform: translate(0%, -50%);
  }
}

/* ///////////////// */

.for-main-img .man-pic {
  height: 100vh;
  object-fit: cover;
}

.ant-switch.ant-switch-checked {
  background-color: #b33afe;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: #b33afe;
}

body {
  font-family: "Inter", sans-serif;
  background: #050527;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Krona One", sans-serif;
}

.ant-image {
  display: block;
}

img {
  max-width: 100%;
}

*:hover {
  box-shadow: none !important;
}

.flex {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.d-block {
  display: block !important;
}

* {
  padding: 0;
  margin: 0;
}

hr {
  opacity: 0.3;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.my-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mainbtn {
  background: var(
    --Linear,
    linear-gradient(180deg, #7f00ff 0%, #a020f0 100%)
  ) !important;
  color: #fff;
  font-size: 14.5px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  border: 0;
  padding: 10px 20px;
  height: auto;
  border-radius: 0;
  display: flex;
  align-items: center;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.mainbtn:hover {
  opacity: 0.8;
  color: #fff !important;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.mainbtn svg {
  margin: 0px 10px;
  font-size: 24px;
}

.auth-box .ant-upload-select {
  width: auto !important;
  height: auto !important;
}

.top {
  width: 100%;
  text-align: right;
  padding: 10px 0;
  color: #fff;
  font-size: 20px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.profile {
  background: transparent !important;
  border: 0;
  padding: 0;
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.profile_btn {
  background: transparent;
  font-weight: bold;
  border: 0;
  padding: 0;
  margin: 0 10px;
  display: block;
}

.profile_btn:hover {
  color: #8f10f7 !important;
}

.profile img {
  width: 36px !important;
  height: 36px !important;
  border-radius: 50% !important;
  margin: 0 10px 0 0;
}

.profileName {
  color: #fff;
  font-size: 16px;
}

.profile svg {
  font-size: 24px;
  color: #fff;
}

.navigation {
  position: absolute;
  width: 100%;
  z-index: 999;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7f00ff+0,a020f0+100&0.5+0,0.5+100 */
  background: linear-gradient(
    to bottom,
    rgba(127, 0, 255, 0.1) 0%,
    rgba(160, 32, 240, 0.1) 0%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.navigation svg {
  color: #fff;
}

.searchbar-top {
  display: flex;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 80px;
}

.searchbar-top svg {
  color: #7f00ff;
}

.searchbar-top-first-searchfield .ant-input-affix-wrapper,
.searchbar-top-second-searchfield .ant-input-group-wrapper,
.searchbar-top-second-searchfield .ant-input-wrapper,
.searchbar-top-second-searchfield .ant-input-group-addon,
.searchbar-top-second-searchfield .ant-input,
.searchbar-top-second-searchfield .ant-btn {
  border: 0 !important;
  background: transparent;
}

.searchbar-top-first-searchfield svg {
  font-size: 20px;
  font-weight: bold;
}

.searchbar-top-first-searchfield button {
  display: none !important;
}

.searchbar-top-second-searchfield button {
}

.navigation .ant-menu-light {
  background: transparent;
  border-bottom: none;
  justify-content: center;
}

.navigation .ant-menu-horizontal .ant-menu-item {
  padding: 16px 20px;
  color: #fff;
  text-transform: uppercase;
}

.navigation
  .ant-menu-item:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ):hover
  .ant-menu-title-content,
.navigation
  .ant-menu-overflow-item.ant-menu-item.ant-menu-item-active.ant-menu-item-selected.ant-menu-item-only-child {
  color: #ad2eff !important;
}

.navigation .ant-menu-light.ant-menu-horizontal > .ant-menu-item-active::after,
.navigation
  .ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-selected::after {
  border-bottom-color: #ad2eff;
}

.navigation .mainbtn {
  margin: 0 10px;
}

.logo {
  width: 40px !important;
}

.my-account-profile {
  width: 100%;
  position: relative;
  /* background-color: #faf9f5; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  letter-spacing: normal;
  text-align: left;
  font-size: 18px;
  color: #333;
}

.side-menu-parent {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.side-menu-parent {
  /* width: 1633px; */
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 20px 0 0;
  gap: 29px;
  text-align: left;
  font-size: 28px;
  color: #000;
}

.side-menu-parent {
  display: block;
  padding: 10px 15px;
}

.about-us-section {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 23px;
  max-width: calc(100% - 306px);
}

.location-drop .autocomplete-dropdown-container {
  position: absolute;
  max-width: 340px;
  z-index: 9;
  color: black;
}

.location-drop .suggestion-item.googleSuggestion {
  padding: 5px 10px;
}

.location-drop input {
  color: #fff;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.full-name2 {
  margin-block-start: 0;
  margin-block-end: 8px;
  font-size: 18px;
}

.full-name1,
.jake-dawson1 {
  position: relative;
  font-weight: 500;
}

.full-name1 {
  height: 42px;
  display: inline-block;
  z-index: 3;
}

.full-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 11px;
}

.changepassword,
.full-name {
  gap: 32px;
}

.jake-dawson {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}

.jake-dawson1 {
  position: relative;
  font-weight: 500;
  font-size: 18px;
}

.jake-dawson1 {
  color: #000;
  z-index: 4;
  margin-top: -10px;
}

.full-name {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
}

.wrapper-group-1000001858 {
  width: 150px;
  height: 150px;
  display: block;
  margin: 0 auto 35px;
}

.wrapper-group-1000001858 img {
  width: 150px !important;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.wrapper-group-1000001858-child {
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.wrapper-group-1000001858 img {
  width: 100%;
}

.phone-number {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.phone-number1 {
  font-weight: 500;
  font-size: 18px;
}

.a-b-c,
.frame-child,
.phone-number1 {
  position: relative;
  z-index: 3;
}

.gender {
  display: flex;
  justify-content: flex-start;
}

.gender {
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
}

.gender1,
.male {
  position: relative;
  font-weight: 500;
  z-index: 3;
}

.frame-parent,
.gender {
  display: flex;
  justify-content: flex-start;
}

.frame-parent {
  flex-direction: row;
  align-items: flex-end;
  gap: 7px;
  color: #000;
}

.a-b-c,
.frame-child,
.phone-number1 {
  position: relative;
  z-index: 3;
}

.a-b-c {
  font-weight: 500;
  white-space: nowrap;
  font-size: 18px;
}

.logo-rectangle {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  gap: 32px;
  background-color: #f3f8fd;
  padding: 20px;
}

.profile-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 11px;
  max-width: 100%;
}

.profile-information {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 28px;
  font-weight: 700;
}

.profile-information-wrapper {
  text-align: center;
}

.email,
.jakesamplecom {
  position: relative;
  font-weight: 500;
  z-index: 3;
}

.jakesamplecom {
  color: #000;
  white-space: nowrap;
}

.gender1,
.male {
  position: relative;
  font-weight: 500;
  z-index: 3;
}

.male {
  color: #000;
}

.b-g {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 11px;
}

/* ///////////////// */
.menu-drawer .ant-drawer-body {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}

/* ///////////////// */
.banner {
  background: url(../public/images/banner.png) no-repeat;
  background-size: cover;
  text-align: left;
  background-attachment: fixed;
  padding: 75px 0 0px;
  overflow: hidden;
}

.banner h3 {
  color: #fff;
  font-size: 80px;
  font-weight: 900;
  margin: 0 0 15px;
  text-transform: uppercase;
  background: url(../public/images/banner-text-bg.png) no-repeat center bottom;
}

.banner h4 {
  color: #fff;
  font-size: 70px;
  font-weight: 300;
  margin: 0 0 35px;
  text-transform: uppercase;
}

.banner p {
  font-size: 16px;
  color: #fff;
  line-height: 18px;
  font-weight: 300;
  margin: 0px 0px 25px 0px;
}

.banner ul li {
  align-items: center;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  list-style-type: none;
  margin: 0 0 15px;
  text-transform: uppercase;
}

.banner ul li svg {
  color: #00ba00;
  background: #fff;
  border-radius: 80%;
  margin-right: 10px;
}

.banner .mainbtn {
  margin-bottom: 85px;
}

.marqueetext {
  background: rgb(41, 35, 141, 0.5);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.marqueetext span {
  color: #363277;
  font-size: 30px;
  font-style: italic;
  font-weight: 600;
  line-height: 40px;
  /* 133.333% */
  letter-spacing: -1.2px;
  text-transform: uppercase;
  margin: 0 10px;
}

.accordion {
  padding: 50px 0 300px;
}

.contentarea {
  background: url(../public/images/content-bg.png) no-repeat center top;
  background-size: 100%;
}

.entertainmenttopbg {
  background: url(../public/images/music-bg.png) no-repeat;
  display: flex;
  justify-content: end;
  padding: 80px 0 100px;
  width: 100%;
  margin-top: -150px;
  position: relative;
  z-index: 2;
}

.entertainmenttopbg-inner {
  background: rgb(255, 255, 255, 0.4);
  padding: 80px 40px;
  border: 4px solid;
  border-right: 0;
  border-color: rgb(255, 255, 255, 0.2);
}

.entertainmenttopbg-inner h3 {
  color: #111;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -5px;
  text-transform: uppercase;
  margin: 0 0 15px;
}

.entertainmenttopbg-inner p {
  color: #181818;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: 0.28px;
  text-transform: uppercase;
  margin: 0 0 25px;
}

.entertainmentbottom {
  background: rgb(255, 255, 255, 0.02);
  border: 4px solid;
  border-color: rgb(255, 255, 255, 0.2);
  padding: 140px 40px 100px;
  text-align: center;
  margin: -80px auto 0;
  width: 80%;
}

.entertainmentbottom h6 {
  color: #b33afe;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  margin: 0 0 15px;
}

.entertainmentbottom h5,
.inner-page h5 {
  color: #fff;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
  letter-spacing: -2.4px;
  text-transform: uppercase;
  margin: 0 0 15px;
}

.entertainmentbottom p {
  color: #e4e4e4;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0.6px;
  margin: 0 0 25px;
}

.trending,
.testimonials,
.blog {
  padding: 40px 0;
}

.trending h3,
.testimonials h3,
.blog h3,
.footer h3 {
  color: #b33afe;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: 0.84px;
  text-transform: uppercase;
  margin: 0 0 10px;
}

.trending h2,
.testimonials h2,
.blog h2,
.footer h2,
.f-50 {
  color: #fff;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -1.44px;
  text-transform: uppercase;
  margin: 0 0 25px;
}

.trend {
  position: relative;
  overflow: hidden;
  padding: 0 10px;
}

.trend img {
  width: 100% !important;
  height: 250px !important;
  object-fit: cover;
  border-radius: 5px;
}

.trend h4 {
  color: #fff;
  opacity: 1;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  /* 60% */
  letter-spacing: -1px;
  text-transform: uppercase;
  text-wrap: nowrap;
  position: absolute;
  z-index: 2;
  left: 30px;
  bottom: 12px;
  text-shadow: 4px 7px 7px #000;
}

.trend:hover h4 {
  display: none;
}

.trend h5 {
  padding: 10px 20px;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.96px;
  text-transform: uppercase;
  /* position: absolute;
  z-index: 2;
  left: 30px;
  bottom: -3px; */
}

.Label {
  color: #fff !important;
}

.testimonials .item {
  padding: 0 15px;
}

.clientreview {
  padding: 25px 20px;
  background: #29238d;
  border-radius: 5px;
  margin: 25px 0;
  box-sizing: border-box;
}

.clientreview h3 {
  color: #cbcbcb;
  font-family: "Krona One", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -2.4px;
  text-transform: uppercase;
}

.clientreview p {
  color: #cbcbcb;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 213.333% */
  letter-spacing: 0.3px;
  margin: 0 0 20px;
}

.clientreview h4 {
  color: #b33afe;

  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 186.667% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.clientreview span {
  color: #efefef;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 200% */
}

.clientreview span svg {
  color: #ffbb00;
}

.blogCard {
  background: rgba(71, 71, 119, 0.17);
  backdrop-filter: blur(50px);

}

.blogCard h3 {
  color: #fff;
  font-family: "Krona One", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
  text-transform: uppercase;
}

.blogCard p {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 200;
  line-height: 170%;
  /* 23.8px */
}

.blogCard h4 {
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 0 0 10px;
}

.blogCard h4 span a {
  color: #fff;
  text-decoration: underline;
  font-weight: 300;
}

.blogCard h5 {
  color: #fff;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: normal !important;
  margin: 15px 0;
}

.blogCard h6 {
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
}

.blogCard .price {
  color: #8f10f7;
  font-family: "Krona One", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blogCard .ant-card-head,
.blogCard .ant-card-body {
  padding-left: 0;
  padding-right: 0;
  border: 0;
}

.blogCard .ant-card-body {
  text-align: left;
}

.blogCard {
  margin-bottom: 35px;
  box-shadow: none !important;
  padding: 8px;
  border: 1.8px solid;
  border-color: rgba(110, 110, 160, 0.459);
  border-radius: 0;
  background: transparent;
  text-align: center;
  border-radius: 10px;
}

.blogCard img {
  border-radius: 0px;
  width: 100%;
  height: 180px;
  object-fit: cover;
  object-position: center;
}

.blogCard a {
  color: #b33afe;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 200% */
  letter-spacing: 0.56px;
  text-transform: uppercase;
  text-align: left;
}

.gettickets {
  background: url(../public/images/ticket-now-laptop-bg.png) no-repeat center
    top;
  background-size: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 600px;
  flex-direction: column;
}

.gettickets h3 {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  /* 120% */
  letter-spacing: -2px;
  text-transform: uppercase;
}

.gettickets p {
  color: #f6f6f6;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 100% */
  letter-spacing: 0.96px;
  text-transform: uppercase;
  margin: 0 0 25px;
}

.gettickets .mainbtn {
  background: transparent !important;
  font-size: 14.5px;
  border: 2px solid #fff;
}

.gettickets .mainbtn:hover {
  border: 2px solid #fff !important;
}

.footer {
  background: url(../public/images/footer-bg.png) no-repeat center top;
  background-size: cover;
  padding: 80px 0 50px;
  margin: 0;
  text-align: center;
  border-bottom: 2px solid #0c0c36;
}

.footer p {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.625px;
  /* 171.875% */
  letter-spacing: 0.36px;
  text-transform: capitalize;
  margin: 0 0 50px;
}

.footer input {
  border-radius: 0;
}

.footerbtn {
  color: #fff;
  background: #29238d !important;
  font-size: 26px;
  padding: 0 30px;
  height: 50px;
  border-radius: 0;
}

.footer ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer ul li {
  padding: 0 10px;
  margin: 0 0 25px;
}

.footer ul li a {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.2px;
  /* 121.333% */
  letter-spacing: 3.9px;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.socialicon {
  margin: 10px 10px 0px;
  display: inline-block;
}

.bottom {
  border-top: 2px solid #02020e;
  padding: 40px 0;
  width: 100%;
  margin: 0;
  text-align: center;
}

.bottom p {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.625px;
  /* 137.5% */
  letter-spacing: 0.45px;
  text-transform: capitalize;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10vmin;
  overflow: hidden;
  /* transform: skew(5deg); */
}

.container .card {
  flex: 1;
  transition: all 1s ease-in-out;
  height: 75vmin;
  position: relative;
  overflow: hidden;
}

.container .card .ant-image {
  height: 75vmin !important;
  width: 100%;
}

.container .card .card__head {
  color: #fff;
  background: #29238d;
  padding: 0.5em;
  transform: rotate(-90deg);
  transform-origin: 0% 0%;
  transition: all 0.5s ease-in-out;
  /* min-width: 100%; */
  width: 75vmin;
  text-align: center;
  position: absolute;
  /* bottom: 0; */
  left: 0;
  font-size: 1em;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container .card:hover {
  flex-grow: 10;
}

.container .card:hover img {
  filter: grayscale(0);
}

.container .card:hover .card__head {
  text-align: center;
  width: 100%;
  top: calc(100% - 2em);
  color: #fff;
  background: #29238d;
  font-size: 2em;
  transform: rotate(0deg) skew(0deg);
  /* transform: rotate(0deg) skew(-5deg); */
}

.container .card .card__head svg {
  color: #a020f0;
  font-size: 26px;
  background: #fff;
  border-radius: 50%;
  margin: 0px 41px 0 0;
  text-align: center;
}

.container .card:hover .card__head svg {
  display: none;
}

.container .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 1s ease-in-out;
  filter: grayscale(100%);
}

.container .card:not(:nth-child(10)) {
  margin-right: 1em;
}

.inner-page {
  padding: 130px 0;
}

.styledPagination a {
  color: #fff;
}

/* ---------------------------------------------------
  Login page start
----------------------------------------------------- */
.auth-banner-section {
  min-height: 100vh;
  display: flex;
  align-items: end;
  overflow: hidden;
}

.auth-banner {
  background-image: url(../public/images/auth-banner.png);
  background-size: cover;
  min-height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-banner label {
  color: #737373;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.auth-box {
  border-radius: 20px;
  border: 1px solid #c9c9c9;
  background: linear-gradient(180deg, #fff 0%, #fff 100%);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.03);
  padding: 50px 60px;
}

.check-label {
  color: #6b6b6b;

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  padding-left: 0;
}

.forgot-text {
  color: #0b89f5 !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: underline !important;
  text-transform: capitalize;
}

.already-account-text {
  padding: 10px 0;
  color: #3f3f3f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.already-account-text span {
  color: #b33afe;
}

.web-input {
  margin-bottom: 10px;

  height: 52px;
  border-radius: 0px;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 50px 10px 10px;
}

i.right-icon {
  position: absolute;
  top: 25px;
  transform: translateY(-50%);
  right: 20px;
  cursor: pointer;
  color: #c6c5c5 !important;
}

.form-field {
  position: relative;
}

.auth-heading {
  color: #090637;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 115.385% */
  letter-spacing: -1.04px;
  text-transform: uppercase;
  text-align: center;
}

.auth-p {
  color: #535353;

  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  /* 240% */
  text-transform: capitalize;
  padding-bottom: 20px;
}

/* .vi, :where(.vi__character--inactive){
  border: 1px solid #DDD !important;
background: #FFF !important;
box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.10);
width: 70px !important;
height: 70px !important;
color: #000 !important;
} */

.color-red {
  color: red;
}

.ant-form-item .ant-form-item-label > label {
  color: #333;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 133.333% */
  letter-spacing: 0.3px;
  margin-bottom: 10px;
}

/* ---------------------------------------------------
  Login page End
----------------------------------------------------- */

/* ---------------------------------------------------
  SignUp page start
----------------------------------------------------- */
.signup-with-btn {
  border: 1px solid #999;
  border-radius: 5px;
  padding: 0px 20px;
  text-decoration: none;
  color: #333;
  text-align: center;
  font-family: "Figtree", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 54px;
}

.signup-with-btn:hover {
  background: rgb(20, 151, 228);
  background: linear-gradient(
    180deg,
    rgba(20, 151, 228, 1) 0%,
    rgba(0, 127, 202, 1) 100%
  );
  color: #fff;
}

.g-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.back-login {
  color: #333;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 106%;
  text-decoration-line: underline;
  text-transform: capitalize;
  display: block;
  padding-top: 16px;
}

.code-boxs {
  border-radius: 4px;
  max-width: 400px;
  background: #fff;
}

.code-boxs .form-control {
  display: block;
  height: 78px;
  margin-right: 0.5rem;
  text-align: center;
  font-size: 1.25rem;
  min-width: 0;
  background-color: #f8f8f8;
}

form .form-control:last-child {
  margin-right: 0;
}

.auth-box2 {
  border-radius: 20px;
  border: 1px solid #c9c9c9;
  background: linear-gradient(180deg, #fff 0%, #fff 100%);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.03);
  padding: 50px 10px;
}

.have-account {
  border: 1px solid #999;
  border-radius: 5px;
  padding: 0px 20px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-family: "Figtree", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 54px;
  background: rgb(20, 151, 228);
  background: linear-gradient(
    180deg,
    rgba(20, 151, 228, 1) 0%,
    rgba(0, 127, 202, 1) 100%
  );
  width: 280px;
  margin: 5px 0;
}

.have-account:hover {
  color: #fff;
}

.new-user {
  border: 1px solid #999;
  border-radius: 5px;
  padding: 0px 20px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-family: "Figtree", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 54px;
  background: #636363;
  background: linear-gradient(180deg, #636363 0%, #373737 100%);
  margin: 5px 0;
  width: 280px;
}

.new-user:hover {
  color: #fff;
}

/* ---------------------------------------------------
  SignUp page End
----------------------------------------------------- */

/* ---------------------------------------------------
  help page Start
----------------------------------------------------- */
.help-frame {
  background-image: url(../public/images/contact-bg.png);
  padding: 50px;
  background-size: cover;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  text-align: left;
}

.help-frame h6 {
  color: #fff;

  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 5px;
}

.help-frame span {
  color: #c9c9c9;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 0;
}

.help-frame p {
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 8px;
  padding: 20px 0;
}

.details-card {
  background: rgb(255, 255, 255, 0.02);
  border: 4px solid;
  border-color: rgb(255, 255, 255, 0.2);
  padding: 40px;
  text-align: center;
}

.details-card .ant-form-item .ant-form-item-label > label {
  color: #fff;
}

.contact-form label {
  color: #000 !important;
}

/* ---------------------------------------------------
  help page End
----------------------------------------------------- */
/* ---------------------------------------------------
  My Stay Booking page start
----------------------------------------------------- */
.categeorySlider {
  padding: 15px 45px;
  position: relative;
  border: 1px solid #474777;
  background: rgba(71, 71, 119, 0.17);
  backdrop-filter: blur(50px);
}

.topCategeorySlider {
  display: flex;
  align-items: center;
  cursor: pointer;
  /* justify-content: center; */
}

.topCategeorySlider img {
  margin: 0 15px 0 0;
  width: 20px !important;
}

.topCategeorySlider span {
  color: #fff;
  font-family: "Krona One", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.categeorySlider .alice-carousel__prev-btn,
.categeorySlider .alice-carousel__next-btn {
  padding: 0;
  width: 24px;
  height: 24px;
  top: 0px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;
}

.categeorySlider .alice-carousel__next-btn {
  right: -25px;
  position: absolute;
}

.categeorySlider .alice-carousel__prev-btn {
  left: -35px;
  position: absolute;
}

.categeorySlider .alice-carousel__prev-btn-item,
.categeorySlider .alice-carousel__next-btn-item {
  padding: 0;
}

.categeorySlider .alice-carousel__prev-btn-item,
.categeorySlider .alice-carousel__next-btn-item {
  color: #8f10f7 !important;
}

.booking-card {
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #f0f0f0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
  position: relative;
  /* margin: 8px; */
}

.booking-card-name {
  color: #000;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  padding: 10px 0;
}

.booking-card-span {
  color: #818181;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.booking-card-price {
  color: #000;

  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  padding-bottom: 10px;
}

.booking-card-price small {
  font-size: 16px;
}

.main-heading {
  color: #000;

  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 75px;
}

.arrow-box .main-heading {
  text-align: center;
}

.arrow-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.arrow-box .arrow {
  position: absolute;
  color: #000;
  text-decoration: none;
  left: 0;
  font-size: 22px;
  cursor: pointer;
}

.verify-text {
  font-size: 13px;
  padding-left: 10px;
  color: #5555ff;
  text-decoration: underline;
  cursor: pointer;
}

.verify-text:hover {
  color: #7f00ff;
}

.Verified-txt {
  font-size: 13px;
  padding-left: 10px;
  color: green;

  display: inline-flex;
  gap: 5px;
}

.disabledbutton {
  background-color: #fff;
  color: black;
}

.purple-btn {
  background: var(
    --Linear,
    linear-gradient(180deg, #7f00ff 0%, #a020f0 100%)
  ) !important;
  color: #fff !important;
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding: 10px 58px;
  height: 50px;
  border-radius: 8px;
  border: none;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  min-width: 150px;
  justify-content: center;
}

.left-div {
  border-radius: 6px;
  border: 1px solid #fff;
  background: rgba(71, 71, 119, 0.17);
  backdrop-filter: blur(50px);
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.04);
  /* height: 100%; */
}

.left-div .ant-slider .ant-slider-rail,
.left-div .ant-slider:hover .ant-slider-rail {
  background-color: #fff;
}

.left-div .ant-form-item .ant-form-item-label > label {
  color: #fff;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 185.714% */
  text-transform: uppercase;
  font-family: "Krona One", sans-serif;
}

.filter-heading {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  padding: 20px 30px;
  text-align: left;
  font-family: "Krona One", sans-serif;
}

.web-input2,
.ant-select-selector {
  margin-bottom: 10px;
  width: 100%;
  height: 52px;
  border-radius: 0px;
  border: 0;
  border-bottom: 2px solid #fff !important;
  background: transparent !important;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  color: #fff !important;
}

.left-div .ant-select .ant-select-arrow {
  color: #fff;
}

.left-div .filter-canvas {
  padding: 20px 30px;
}

.left-div .ant-space-item {
  width: 100%;
}

.left-div .ant-select-selector {
  background: transparent;
  color: #fff;
  border: 0 !important;
  border-bottom: 2px solid #fff !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.left-div .ant-radio-wrapper .ant-radio-inner {
  width: 18px !important;
  height: 18px !important;
}

.left-div .ant-radio-wrapper:hover .ant-radio-inner,
.left-div .ant-radio-wrapper .ant-radio-inner,
.blogCard .ant-radio-wrapper:hover .ant-radio-inner,
.blogCard .ant-radio-wrapper .ant-radio-inner {
  border-color: #fff;
  background-color: transparent;
}

.left-div .ant-radio-wrapper .ant-radio-checked .ant-radio-inner,
.blogCard .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #8f10f7;
  background-color: #8f10f7;
}

.left-div .ant-radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 173.333% */
  font-family: "Krona One", sans-serif;
}

.blogCard .ant-radio-wrapper {
  margin-bottom: 10px;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 173.333% */
  font-family: "Krona One", sans-serif;
}

.sort-by {
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  padding: 14px 0;
}

.fliterMenu .ant-drawer-body {
  background: transparent !important;
}

.ant-drawer-content.fliterMenu {
  background-color: rgba(143, 16, 247, 0.4);
}

.ant-drawer-content.fliterMenu .left-div {
  height: auto;
}

.ant-drawer-content.fliterMenu svg {
  color: #fff;
}

.blue-link {
  color: #1497e4;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.ant-ribbon {
  padding: 5px 12px;
}

.ant-ribbon-text {
  padding-inline: 10px;
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.ribbon-blue {
  --f: 10px;
  /* control the folded part*/
  --r: 15px;
  /* control the ribbon shape */

  position: absolute;
  top: 10px;
  padding-inline: 10px;
  background: #2d308b;
  border-bottom: var(--f) solid #0005;
  padding: 8px 12px;
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
}

.ribbon-yellow {
  --f: 10px;
  /* control the folded part*/
  --r: 15px;
  /* control the ribbon shape */

  position: absolute;
  top: 10px;
  padding-inline: 10px;
  background: #dd9f00;
  border-bottom: var(--f) solid #0005;
  padding: 8px 12px;
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
}

.left {
  left: calc(-1 * var(--f));
  border-right: var(--r) solid #0000;
  clip-path: polygon(
    100% 0,
    0 0,
    0 calc(100% - var(--f)),
    var(--f) 100%,
    var(--f) calc(100% - var(--f)),
    100% calc(100% - var(--f)),
    calc(100% - var(--r)) calc(50% - var(--f) / 2)
  );
  top: 20px !important;
  z-index: 2;
}

.showing-entries {
  color: #000;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

/* ---------------------------------------------------
  My Stay Booking page End
----------------------------------------------------- */
.tickets-sectionbg {
}

.ticketDetailsBanner {
  margin: 25px 0 0;
}

.tickets-sectionbg h4 {
  display: inline-block;
}

.tickets-sectionbg h4.ant-list-item-meta-title {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: normal;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 1px;
}

.slider-container {
  margin: 25px 0;
}

.thumbimage {
  padding: 0 5px;
  overflow: hidden;
  box-sizing: border-box;
}

.slider-container img.big-image {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
  margin: 0;
}

.facilityTag {
  border-radius: 4px;
  background: #fff;
  padding: 10px 25px 10px 10px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  margin: 15px 10px 0px 0;
}

.facilityTag svg {
  margin: 0 5px 0 0;
}

.selectTickets {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#7f00ff+30,9226ff+50,7f00ff+70&0+0,0.8+0,1+0,1+100 */
  background: linear-gradient(
    135deg,
    rgba(127, 0, 255, 1) 0%,
    rgba(127, 0, 255, 1) 30%,
    rgba(146, 38, 255, 1) 50%,
    rgba(127, 0, 255, 1) 70%,
    rgba(127, 0, 255, 1) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  border-radius: 8px;
  border: 1px solid #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.04);
  padding: 40px 30px;
  box-sizing: border-box;
}

.selectTickets .web-btn:hover {
  background: rgb(13, 150, 193);
  background: linear-gradient(
    121deg,
    rgba(13, 150, 193, 1) 0%,
    rgba(24, 55, 126, 1) 57%
  ) !important;
}

.ant-pagination-prev button {
  color: #fff !important;
}

.ant-pagination-next button {
  color: #fff !important;
}

.selectTickets h3 {
  color: #fff;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 100% */
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 0 0 15px;
}

.selectTickets h5 {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 130% */
  text-decoration-line: line-through;
}

.selectTickets h2 {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  margin: 0 0 15px;
  font-weight: 400;
  line-height: 26px;
  /* 86.667% */
}

.selectTickets .ant-input-group-wrapper {
  padding: 10px !important;
  border: 1px solid #fff !important;
  margin: 25px 0 !important;
}

.selectTickets .ant-input,
.selectTickets .ant-input::placeholder {
  margin: 0;
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  font-family: "Krona One", sans-serif;
}

.selectTickets .ant-input-search-button {
  background: transparent !important;
  border: 0;
  color: #fff !important;
}

.selectTickets h4 {
  color: #fff;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 100% */
  margin: 0 0 15px;
}

.selectTickets h4 svg {
  margin: 0 10px 0 0;
}

.selectTickets hr {
  margin: 0 0 25px;
}

.selectTickets li.ant-list-item {
  border: 0 !important;
  color: #fff;
  font-family: "Krona One", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 144.444% */
  margin: 0 0 10px;
  padding: 0;
}

/* Table Css */
.styledPagination {
  width: max-content;
  border: 1px solid #e6e9ec !important;
  border-radius: 5px;
  font-family: "Poppins", sans-serif !important;
  overflow: hidden;
  height: fit-content !important;
}

.styledPagination * {
  font-family: "Poppins", sans-serif !important;
}

.styledPagination .ant-pagination-prev {
  padding: 5px 20px;
  height: auto;
  color: #b0b0b0;
  margin: 0;
  border-radius: 0;
  font-weight: 300;
}

.styledPagination .ant-pagination-item {
  padding: 5px 10px;
  height: auto;
  font-weight: 300;
  border-radius: 0;
  margin: 0;
  color: #b0b0b0;
  border: none;
  border-left: 1px solid #e6e9ec !important;
}

.styledPagination .ant-pagination-item-active {
  background-color: #8f10f7 !important;
  color: white !important;
  border: 1px solid #8f10f7 !important;
}

.swal-button {
  background-color: #8f10f7 !important;
}

.styledPagination .ant-pagination-item-active * {
  color: white !important;
}

.styledPagination .ant-pagination-item-active:hover {
  background-color: #8f10f7 !important;
  border-left: 1px solid #8f10f7 !important;
  color: white !important;
}

.styledPagination .ant-pagination-item-active:hover * {
  color: white !important;
}

.styledPagination .ant-pagination-item:hover {
  background-color: #8f10f7 !important;
  border: 1px solid #8f10f7 !important;
  color: white !important;
}

.styledPagination .ant-pagination-item:hover * {
  color: white !important;
}

.styledPagination .ant-pagination-next {
  padding: 5px 20px;
  height: auto;
  margin: 0;
  border-radius: 0;
  color: #b0b0b0;
  font-weight: 300;
  border-left: 1px solid #e6e9ec !important;
}

.styledPagination .ant-pagination-next:hover {
  background-color: #bf9343;
  border-left: 1px solid #bf9343 !important;
  color: white !important;
}

.styledPagination .ant-pagination-prev:hover {
  background-color: #bf9343;
  color: #fff;
}

.styledTable2 {
  min-width: 600px !important;
  width: 100% !important;
  border: none;
}

.styledTable2 table {
  text-align: center !important;
}

.styledTable2 tr th {
  background-color: transparent !important;
  text-align: center !important;
  color: #fff !important;
  text-transform: uppercase;
}

.styledTable2 tr td {
  color: #fff;
}

.styledTable2 .ant-table-wrapper .ant-table-thead > tr > th,
.styledTable2 .ant-table-wrapper .ant-table-thead > tr > td {
  background: transparent;
}

.filterInput {
  width: 100% !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px;
  margin-bottom: 10px;
}

.styledTable2 .ant-table,
.styledTable2
  .ant-table-wrapper
  .ant-table-tbody
  .ant-table-row
  > .ant-table-cell-row-hover,
.styledTable2 .ant-table-container,
.styledTable2 .ant-table-content,
.styledTable2 table,
.styledTable2 thead.ant-table-thead,
.styledTable2 tr,
.styledTable2 .ant-table-cell.ant-table-cell-row-hover {
  background: transparent !important;
}

.quantity-wrapper {
  display: flex;
  justify-content: center;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 20px;
  max-width: fit-content;
  padding: 4px;
  margin: 0 auto;
}

.quantity-wrapper span {
  margin: 0;
  font-size: 12px !important;
  color: #fff;
  font-weight: bold;
}

.quantity-wrapper .ant-btn.ant-btn-circle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: transparent !important;
  border: 1px solid #fff !important;
  outline: 0;
  width: 32px;
  height: 32px;
  margin: 0;
}

.quantity-wrapper .ant-input-number {
  border: 0 !important;
  max-width: 50px;

  font-weight: bold;
}

.quantity-wrapper .ant-input-number-input {
  text-align: center;
  color: #fff !important;
}

.quantity-wrapper .ant-input-number-handler-wrap {
  display: none;
}

.quantity-wrapper .ant-input-number {
  background: transparent !important;
  color: #fff !important;
}

.blogCard .ant-row.ant-form-item-row {
  display: block;
}

.blogCard .ant-form-item .ant-form-item-label > label {
  display: block;
  color: #fff;
  text-align: left;
}

/* Table Css end */
.orderDetailsFiled {
  background: rgba(71, 71, 119, 0.17) !important;
  backdrop-filter: blur(50px);
  border: 1px solid #fff;
  width: 100% !important;
  height: 45px;
  border-radius: 4px;
  display: block;
  width: 100%;
  color: #fff;
}

.text-area-order {
  background: rgba(71, 71, 119, 0.17) !important;
  backdrop-filter: blur(50px);
  border: 1px solid #fff;
  width: 100% !important;
  color: #fff !important;
  height: 150px !important;
  border-radius: 4px;
  display: block;
  width: 100%;
  resize: none !important;
}

.ant-input-number {
  color: #fff;
}

.orderDetailsFiled::placeholder {
  color: #fff !important;
}

.visitoinfo {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 0;
}

hr.smallhr {
  margin: 0;
}

.ticketimage {
  width: 100% !important;
  height: auto !important;
  border-radius: 5px !important;
}

@media (max-width: 1490px) {
  .banner h3 {
    font-size: 60px;
  }

  .banner h4 {
    font-size: 50px;
  }

  .banner .mainbtn {
    margin-bottom: 65px;
  }
}

@media (max-width: 1300px) {
  .gettickets {
    background: url(../public/images/ticket-now-laptop-bg.png) no-repeat center
      top;
    background-size: 100%;
    height: 500px;
  }
}

@media (max-width: 1120px) {
  .banner h3 {
    font-size: 50px;
  }

  .banner h4 {
    font-size: 40px;
  }

  .banner .mainbtn {
    margin-bottom: 65px;
  }
}

@media (max-width: 991px) {
  .switch-destop {
    display: none;
  }
  .banner h3 {
    font-size: 35px;
  }

  .banner h4 {
    font-size: 25px;
  }

  .trending h2,
  .testimonials h2,
  .blog h2,
  .footer h2,
  .entertainmentbottom h5,
  .entertainmenttopbg-inner h3,
  .gettickets h3,
  .inner-page h5,
  .f-50 {
    font-size: 32px;
    line-height: 38px;
  }

  .gettickets {
    background: url(../public/images/ticket-now-bg.png) no-repeat center top;
    background-size: 100%;
    height: 400px;
  }
}

@media (max-width: 767px) {
  .banner {
    padding: 110px 0 0;
  }

  .banner img {
    display: none;
  }

  .trending h2,
  .testimonials h2,
  .blog h2,
  .footer h2,
  .entertainmentbottom h5,
  .entertainmenttopbg-inner h3,
  .gettickets h3,
  .inner-page h5,
  .f-50 {
    font-size: 25px;
    line-height: 30px;
  }

  .container {
    display: block;
    margin: 2vmin;
  }

  .container .card:not(:nth-child(10)) {
    margin-right: 0;
  }

  .gettickets {
    padding: 0 40px;
  }

  .gettickets {
    background-size: cover;
  }

  .ticketimage {
    margin: 0 0 15px !important;
  }
}

@media (max-width: 480px) {
}

.p-Field label {
  color: #fff !important;
  background-color: red;
}

.p-PaymentDetails-group label.p-FieldLabel.Label.Label--empty {
  color: #fff !important;
}

.payment-body label.p-FieldLabel {
  color: red !important;
}

.ag-format-container {
  width: 1142px;
  max-width: 100%;
  margin: 0 auto;
}

.ag-smoke-block {
  z-index: 4;
  position: relative;
}

.ag-smoke-1 {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;

  -webkit-animation: an-smoke-1 6s ease-in-out infinite;
  -moz-animation: an-smoke-1 6s ease-in-out infinite;
  -o-animation: an-smoke-1 6s ease-in-out infinite;
  animation: an-smoke-1 6s ease-in-out infinite;
}

.ag-smoke-2 {
  width: 57.1em;

  z-index: -2;
  top: 7em;
}

.ag-smoke-2,
.ag-smoke-3 {
  position: absolute;
  left: 0;

  -webkit-animation: an-smoke-2 6s ease-in-out infinite;
  -moz-animation: an-smoke-2 6s ease-in-out infinite;
  -o-animation: an-smoke-2 6s ease-in-out infinite;
  animation: an-smoke-2 6s ease-in-out infinite;
}

.ag-smoke-3 {
  width: 100%;

  z-index: -4;
  top: -32em;
}

.ag-smoke-4 {
  width: 61.4em;

  z-index: -3;
  position: absolute;
  top: 12em;
  right: 0;

  -webkit-animation: an-smoke-3 6s ease-in-out infinite;
  -moz-animation: an-smoke-3 6s ease-in-out infinite;
  -o-animation: an-smoke-3 6s ease-in-out infinite;
  animation: an-smoke-3 6s ease-in-out infinite;
}

.ag-smoke__delay-1 {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
}

.ag-smoke__delay-2 {
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  animation-delay: 4s;
}

@-webkit-keyframes an-smoke-1 {
  0% {
    opacity: 0;

    -webkit-transform: translateY(5%) rotate(0.01deg);
    transform: translateY(5%) rotate(0.01deg);
  }

  50% {
    opacity: 0.9;
  }

  to {
    opacity: 0;

    -webkit-transform: translateY(0) rotate(0.01deg);
    transform: translateY(0) rotate(0.01deg);
  }
}

@-moz-keyframes an-smoke-1 {
  0% {
    opacity: 0;

    -moz-transform: translateY(5%) rotate(0.01deg);
    transform: translateY(5%) rotate(0.01deg);
  }

  50% {
    opacity: 0.9;
  }

  to {
    opacity: 0;

    -moz-transform: translateY(0) rotate(0.01deg);
    transform: translateY(0) rotate(0.01deg);
  }
}

@-o-keyframes an-smoke-1 {
  0% {
    opacity: 0;

    -o-transform: translateY(5%) rotate(0.01deg);
    transform: translateY(5%) rotate(0.01deg);
  }

  50% {
    opacity: 0.9;
  }

  to {
    opacity: 0;

    -o-transform: translateY(0) rotate(0.01deg);
    transform: translateY(0) rotate(0.01deg);
  }
}

@keyframes an-smoke-1 {
  0% {
    opacity: 0;

    -webkit-transform: translateY(5%) rotate(0.01deg);
    -moz-transform: translateY(5%) rotate(0.01deg);
    -o-transform: translateY(5%) rotate(0.01deg);
    transform: translateY(5%) rotate(0.01deg);
  }

  50% {
    opacity: 0.9;
  }

  to {
    opacity: 0;

    -webkit-transform: translateY(0) rotate(0.01deg);
    -moz-transform: translateY(0) rotate(0.01deg);
    -o-transform: translateY(0) rotate(0.01deg);
    transform: translateY(0) rotate(0.01deg);
  }
}

@-webkit-keyframes an-smoke-2 {
  0% {
    opacity: 0;

    -webkit-transform: translate(-10%, 10%) rotate(0.01deg);
    transform: translate(-10%, 10%) rotate(0.01deg);
  }

  50% {
    opacity: 0.9;
  }

  to {
    opacity: 0;

    -webkit-transform: translateY(5%) rotate(0.01deg);
    transform: translateY(5%) rotate(0.01deg);
  }
}

@-moz-keyframes an-smoke-2 {
  0% {
    opacity: 0;

    -moz-transform: translate(-10%, 10%) rotate(0.01deg);
    transform: translate(-10%, 10%) rotate(0.01deg);
  }

  50% {
    opacity: 0.9;
  }

  to {
    opacity: 0;

    -moz-transform: translateY(5%) rotate(0.01deg);
    transform: translateY(5%) rotate(0.01deg);
  }
}

@-o-keyframes an-smoke-2 {
  0% {
    opacity: 0;

    -o-transform: translate(-10%, 10%) rotate(0.01deg);
    transform: translate(-10%, 10%) rotate(0.01deg);
  }

  50% {
    opacity: 0.9;
  }

  to {
    opacity: 0;

    -o-transform: translateY(5%) rotate(0.01deg);
    transform: translateY(5%) rotate(0.01deg);
  }
}

@keyframes an-smoke-2 {
  0% {
    opacity: 0;

    -webkit-transform: translate(-10%, 10%) rotate(0.01deg);
    -moz-transform: translate(-10%, 10%) rotate(0.01deg);
    -o-transform: translate(-10%, 10%) rotate(0.01deg);
    transform: translate(-10%, 10%) rotate(0.01deg);
  }

  50% {
    opacity: 0.9;
  }

  to {
    opacity: 0;

    -webkit-transform: translateY(5%) rotate(0.01deg);
    -moz-transform: translateY(5%) rotate(0.01deg);
    -o-transform: translateY(5%) rotate(0.01deg);
    transform: translateY(5%) rotate(0.01deg);
  }
}

@-webkit-keyframes an-smoke-3 {
  0% {
    opacity: 0;

    -webkit-transform: translateY(10%) rotate(0.01deg);
    transform: translateY(10%) rotate(0.01deg);
  }

  50% {
    opacity: 0.9;
  }

  to {
    opacity: 0;

    -webkit-transform: translate(-10%, 5%) rotate(0.01deg);
    transform: translate(-10%, 5%) rotate(0.01deg);
  }
}

@-moz-keyframes an-smoke-3 {
  0% {
    opacity: 0;

    -moz-transform: translateY(10%) rotate(0.01deg);
    transform: translateY(10%) rotate(0.01deg);
  }

  50% {
    opacity: 0.9;
  }

  to {
    opacity: 0;

    -moz-transform: translate(-10%, 5%) rotate(0.01deg);
    transform: translate(-10%, 5%) rotate(0.01deg);
  }
}

@-o-keyframes an-smoke-3 {
  0% {
    opacity: 0;

    -o-transform: translateY(10%) rotate(0.01deg);
    transform: translateY(10%) rotate(0.01deg);
  }

  50% {
    opacity: 0.9;
  }

  to {
    opacity: 0;

    -o-transform: translate(-10%, 5%) rotate(0.01deg);
    transform: translate(-10%, 5%) rotate(0.01deg);
  }
}

@keyframes an-smoke-3 {
  0% {
    opacity: 0;

    -webkit-transform: translateY(10%) rotate(0.01deg);
    -moz-transform: translateY(10%) rotate(0.01deg);
    -o-transform: translateY(10%) rotate(0.01deg);
    transform: translateY(10%) rotate(0.01deg);
  }

  50% {
    opacity: 0.9;
  }

  to {
    opacity: 0;

    -webkit-transform: translate(-10%, 5%) rotate(0.01deg);
    -moz-transform: translate(-10%, 5%) rotate(0.01deg);
    -o-transform: translate(-10%, 5%) rotate(0.01deg);
    transform: translate(-10%, 5%) rotate(0.01deg);
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
}

@media only screen and (max-width: 639px) {
}

@media only screen and (max-width: 479px) {
  .help-frame h6 {
    font-size: 16px;
  }

  .help-frame {
    padding: 20px;
  }

  .help-frame p {
    font-size: 14px;
    padding: 16px 0;
  }

  .socialicon .ant-image {
    width: 32px;
  }

  .contact-form .ant-form-item {
    margin-bottom: 0;
  }

  .contact-form .for-gutr-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .inner-page {
    padding: 80px 0;
  }

  .help-frame span {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .ag-format-container {
    width: 750px;
  }
}

@media (min-width: 980px) and (max-width: 1161px) {
  .ag-format-container {
    width: 960px;
  }
}

.content {
  position: relative;
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: 99;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-image {
  width: 100%;
}

.content-details {
  position: absolute;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  z-index: 100;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 1s ease-in-out 0s;
  -moz-transition: all 1s ease-in-out 0s;
  transition: all 1s ease-in-out 0s;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details p {
  color: #fff;
  font-size: 15px;
  line-height: 30px;
  font-weight: 400;
  padding: 20px;
}

.fadeIn-bottom {
  top: 80%;
}

.fadeIn-top {
  top: 20%;
}

.fadeIn-left {
  left: 20%;
}

.fadeIn-right {
  left: 80%;
}

/* 
.portfolioCard .portfolioChild {
  opacity: 1;
  -moz-transform: translateX(0px) translateY(0px);
  -webkit-transform: translateX(0px) translateY(0px);
  -o-transform: translateX(0px) translateY(0px);
  -ms-transform: translateX(0px) translateY(0px);
  transform: translateX(0px) translateY(0px);
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.portfolioCard:hover .portfolioChild {
  opacity: 0;
  -moz-transform: translateX(0px) translateY(15px);
  -webkit-transform: translateX(0px) translateY(15px);
  -o-transform: translateX(0px) translateY(15px);
  -ms-transform: translateX(0px) translateY(15px);
  transform: translateX(0px) translateY(15px);
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
} */

.tabNavigation {
  cursor: pointer;
  padding: 15px 20px;
  font-size: 16px;
}

.tabNavigation.active {
  background: rgba(143, 16, 247, 0.4);
}

/*  */

.details-card {
  border-radius: 6px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.04);
  padding: 24px;
  margin-bottom: 10px;
}

.review-box {
  padding: 30px 0;
  text-align: left;
}

.review-box p {
  color: #6a6a6a;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.for-media-object {
  display: flex;
  gap: 14px;
  align-items: center;
}

.review-box .for-media-object img {
  border-radius: 100px;
  width: 70px;
  object-fit: cover;
  height: 70px;
  object-position: center;
}

h6.form-label {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -1.44px;
  text-transform: uppercase;
  margin: 0 0 25px;
}

.rate {
  height: 46px;
  padding: 0 10px;
}

.web-textarea {
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #000;
  background-color: #f9f9f9;
  padding: 10px 50px 10px 10px;
  resize: none;
}

.web-btn {
  background: rgb(13, 150, 193);
  background: linear-gradient(
    121deg,
    rgba(13, 150, 193, 1) 0%,
    rgba(24, 55, 126, 1) 57%
  );
  color: #fff !important;
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding: 10px 58px;
  height: 50px;
  border-radius: 8px;
  border: none;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  min-width: 150px;
  justify-content: center;
}

/*  */
.socail-icon {
  margin: 0 auto;
}

.socail-icon h4 {
  margin: 25px 0 15px;
}

.socail-icon svg {
  margin: 0 10px 0 0;
  color: #fff;
  font-size: 32px;
}

.socail-icon.black-icon svg {
  color: #000;
}

.tabsorganization .react-tabs__tab--selected {
  color: #fff;
  background-color: rgba(143, 16, 247, 0.4);
}

.tabsorganization .react-tabs__tab:after {
  display: none;
}

.tabsorganization h4 {
  color: #fff;
}


/* ////////////// */

.for-new-bg{
    border: 1px solid #fff;
    border-radius: 12px;
    background-color: rgb(179 58 254 / 16%);
}

.filter-heading.abc {
  background-color: #9410ffad;
}


.home-page-slider .blogCard{
  margin: 10px !important;
}

